<form [formGroup]="formGroup">
    <mat-label [isRequired]="motive">{{ 'diagnosis_motive_label' | locale }}</mat-label>
    <mat-form-field>
        <textarea
            matInput
            formControlName="motive"
            [placeholder]="'diagnosis_motive_label' | locale"
            rows="4"
            autocomplete="off">
        </textarea>
        <mat-error>{{ motive | errorMessage }}</mat-error>
    </mat-form-field>

    <div class="select-container">
        <app-select
            label="diagnosis_resolution_label"
            [form]="formGroup"
            controlName="resolution"
            placeholder="diagnosis_resolution_placeholder"
            [items]="resolutionOptions"
            [displayValue]="displayResolutionFn"
            (selectedOption)="checkOptionalFields($event)">
        </app-select>
    </div>

    <mat-checkbox
        *ngIf="call?.provider?.requiresPrescriptionForAnotherCountry"
        class="red"
        formControlName="requiresPrescription">
        {{ "diagnosis_prescription_another_country" | locale }}
    </mat-checkbox>

    <mat-label [isRequired]="patientRecords">{{ 'diagnosis_patient_records' | locale }}</mat-label>
    <mat-form-field>
        <textarea
            matInput
            formControlName="patientRecords"
            [placeholder]="'diagnosis_patient_records' | locale"
            rows="4"
            autocomplete="off">
        </textarea>
        <mat-error>{{ patientRecords | errorMessage }}</mat-error>
    </mat-form-field>

    <mat-label [isRequired]="text">{{ 'diagnosis_text_label' | locale }}</mat-label>
    <mat-form-field>
        <textarea
            matInput
            formControlName="text"
            [placeholder]="'diagnosis_text_label' | locale"
            rows="4"
            autocomplete="off">
        </textarea>
        <mat-error>{{ text | errorMessage }}</mat-error>
    </mat-form-field>

    <div class="select-container">
        <app-autocomplete
            label="diagnosis_presumptive_diagnosis_label"
            [form]="formGroup"
            controlName="diagnosis"
            [items]="filteredDiagnosisList"
            [displayFn]="displayDiagnosisFn"
            placeholder="diagnosis_presumptive_diagnosis_placeholder">
        </app-autocomplete>
    </div>

    <mat-label [isRequired]="treatment">{{ 'diagnosis_treatment' | locale }}</mat-label>
    <mat-form-field>
        <textarea
            matInput
            formControlName="treatment"
            [placeholder]="'diagnosis_treatment' | locale"
            rows="4"
            autocomplete="off">
        </textarea>
        <mat-error>{{ treatment | errorMessage }}</mat-error>
    </mat-form-field>

    <mat-label>{{ 'call_notes' | locale }}</mat-label>
    <mat-form-field>
        <textarea
            matInput
            formControlName="notes"
            [placeholder]="'call_notes' | locale"
            rows="4"
            autocomplete="off">
        </textarea>
        <mat-error>{{ notes | errorMessage }}</mat-error>
    </mat-form-field>

    <mat-checkbox formControlName="problemPatient">{{ "diagnosis_problem_patient" | locale }}</mat-checkbox>
</form>

<button
    mat-flat-button
    color="primary"
    (click)="continue()"
    [disabled]="formGroup.invalid">
    {{ "continue" | locale }}
    <mat-icon svgIcon="arrow-right" class="east"></mat-icon>
</button>
